import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

// TODO: Provider で持つほうが良いが一旦今のまま単に state 化する
export const useTheme = (): 'light' | 'dark' => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light')
  const router = useRouter()

  useEffect(() => {
    if (router.query.__light) {
      setTheme('light')
      return
    }
    if (router.query.__dark) {
      setTheme('dark')
      return
    }

    const date = new Date()
    const hour = date.getHours()
    setTheme(hour >= 6 && hour <= 17 ? 'light' : 'dark')
  }, [router.query.__dark, router.query.__light])

  return theme
}
