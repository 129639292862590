import { useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'

import { Navigation } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

import { config } from '~/config'
import { useTheme } from '~/libs/theme'

const Hero = () => {
  const theme = useTheme()

  return (
    <div
      className={`grid-cols-2 md:grid ${
        { light: 'text-black bg-white', dark: 'text-white bg-dark' }[theme]
      }`}
    >
      <img
        src={`/images/${
          { light: 'logo-mb.svg', dark: 'logo-mb-dark.svg' }[theme]
        }`}
        className="m-auto md:hidden pt-6"
        alt="logo"
      />

      <div className="relative md:h-[719px]">
        <div className="py-20 mx-auto md:py-56">
          <img
            src={`/images/${
              { light: 'logo.svg', dark: 'logo-dark.svg' }[theme]
            }`}
            className="w-36 h-36 sm:w-56 sm:h-56 m-auto"
            alt="logo"
          />
        </div>
        <Image
          alt="logo"
          src="/images/logo-bg.svg"
          fill
          className="fill-[#8D8D8D] opacity-5 object-cover hidden sm:inline-block"
        />
      </div>

      <div className="px-4 py-4 text-center text-white bg-secondary md:pt-10 md:pb-4">
        <img
          src="/images/message_pc.svg"
          alt="message"
          className="hidden mx-auto lg:block"
        />
        <img
          src="/images/message.svg"
          alt="message"
          className="mx-auto lg:hidden"
        />

        <div className="mt-6 text-sm">
          <p className="my-2">Foodies Primeは予約の取れない人気飲食店に</p>
          <p className="my-2">
            <span className="block my-2 md:my-0 md:inline-block">
              一緒に行きたいと思える人と
            </span>{' '}
            行くことができるサービスです。
          </p>

          <p className="my-2 mt-10">本サービスは招待制となっておりますので、</p>
          <p className="my-2">会員の方にご招待いただくか、</p>
          <p className="my-2">下ボタンから登録申請をお願い致します。</p>
        </div>

        <div
          className={`mt-10 md:w-[577px] mx-auto ${
            { light: 'text-black bg-white', dark: 'text-white bg-dark' }[theme]
          }`}
        >
          <div className="gap-4 px-4 py-8 space-y-4 md:flex md:justify-center md:space-y-0">
            <div>
              <p className="text-sm font-bold">非会員の方はこちら</p>
              <a
                className="block py-2 mx-auto mt-1 text-base text-white bg-secondary w-60"
                href={config.applyUrl}
              >
                登録申請する
              </a>
            </div>

            <div>
              <p className="text-sm font-bold">会員の方はこちら</p>
              <Link
                href={config.loginUrl.line()}
                className="block py-2 mx-auto mt-1 text-base text-white bg-secondary w-60"
              >
                LINE でログイン
              </Link>
            </div>
          </div>
        </div>

        <div className="sm:flex sm:items-center justify-center gap-4 my-6 text-white">
          <p className="text-sm">アプリ版ダウンロード</p>
          <div className="flex items-center justify-center gap-4">
            <a href={config.appstoreUrl}>
              <img src="/images/appstore.svg" alt="appstore" />
            </a>
            <a href={config.googleplayUrl}>
              <img src="/images/googleplay.svg" alt="googleplay" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const About = () => {
  return (
    <div className="bg-gold text-center text-white py-20">
      <div className="px-4">
        <img
          src="/images/about_foodies_prime.svg"
          alt="about"
          className="mx-auto"
        />

        <p className="mt-2">Foodies Primeについて</p>

        <div className="mt-10">
          <p className="my-2">誰と食べるかで味は変わる。 </p>
          <p className="my-2">予約がなかなか取れない人気飲食店。</p>
          <p className="my-2">せっかくなら最大限、おいしく食べたい。</p>
        </div>
        <div className="mt-10">
          <p className="my-2">
            Foodies Primeでは、
            <span className="block md:inline-block">
              厳しく審査された招待制によって、
            </span>
          </p>
          <p className="my-2">厳選されたフーディーのみが登録されています。</p>
          <p className="my-2">
            マナーや気配りができ、
            <span className="block md:inline-block">
              心地よく食事を楽しめる方たちと、
            </span>
          </p>
          <p className="my-2">一緒に素敵な時間を過ごしませんか？</p>
        </div>
      </div>
    </div>
  )
}

const Pictures = () => {
  return (
    <div className="bg-gold text-center text-white md:py-10">
      <div className="hidden overflow-hidden md:flex">
        <img src="/images/foodies1.png" alt="Foodies1" />
        <img src="/images/foodies2.png" className="self-end" alt="Foodies2" />
        <img src="/images/foodies3.png" alt="Foodies3" />
        <img src="/images/foodies4.png" className="self-start" alt="Foodies4" />
        <img src="/images/foodies5.png" alt="Foodies5" />
        <img src="/images/foodies1.png" alt="Foodies6" />
      </div>

      <div className="flex md:hidden overflow-hidden h-[200px] w-full">
        <img
          src="/images/foodies-mb.png"
          alt="Foodies-mb"
          className="object-cover w-full"
        />
      </div>
    </div>
  )
}

type ArrowSwiperProps = {
  id: string
  direction: 'left' | 'right'
  isHidden: boolean
}
const ArrowSwiper = (props: ArrowSwiperProps) => {
  const theme = useTheme()
  if (props.isHidden) return null
  console.log('🚀 ~ file: index.tsx:176 ~ ArrowSwiper ~ props:', props)

  return (
    <div
      id={props.id}
      className={`swiper-button-next absolute top-[40%] z-20 
        ${props.direction === 'left' && 'left-2'}
        ${props.direction === 'right' && 'right-2'}
      `}
    >
      <div
        className={`relative flex items-center justify-center w-10 h-10 rounded-full ${
          { light: 'bg-gray', dark: 'bg-black' }[theme]
        }`}
      >
        <div
          className={`relative flex items-center justify-center w-10 h-10 rounded-full ${
            { light: 'bg-gray', dark: 'bg-black' }[theme]
          }`}
        >
          <div
            className={`absolute top-[14px] w-3 h-3 border-t-2 ${
              { light: 'border-t-black', dark: 'border-t-white' }[theme]
            }
          ${props.direction === 'left' && 'border-l-2 left-4 -rotate-45'}
          ${props.direction === 'right' && 'border-r-2 right-4 rotate-45'}`}
          />
        </div>
      </div>
    </div>
  )
}

const FeaturesMobile = () => {
  const [isBeginning, setIsBeginning] = useState(false)
  const [isEnd, setIsEnd] = useState(false)
  const theme = useTheme()

  return (
    <div
      className={`relative md:hidden ${
        {
          light: 'text-black',
          dark: 'text-white bg-dark',
        }[theme]
      }`}
    >
      <Swiper
        modules={[Navigation]}
        navigation={{
          prevEl: '#button_prev',
          nextEl: '#button_next',
        }}
        onBeforeInit={(swiper) => {
          setIsBeginning(swiper.isBeginning)
          setIsEnd(swiper.isEnd)
        }}
        onActiveIndexChange={(swiper) => {
          setIsBeginning(swiper.isBeginning)
          setIsEnd(swiper.isEnd)
        }}
      >
        <SwiperSlide
          className={
            {
              light: 'text-black bg-gold bg-opacity-20 py-6 h-auto',
              dark: 'text-white bg-dark py-6 h-auto',
            }[theme]
          }
        >
          <div>
            <img
              src="/images/feature1.png"
              className="mx-auto mb-4 h-[520px]"
              alt="特徴1"
            />
            <div className="text-lg font-bold text-center">食事会募集</div>
            <p className="px-4 mt-4 text-sm">
              さまざまな条件で食事会の参加者を募集することができます。また、募集中の食事会に応募することもできます。
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide
          className={`inline-flex justify-center ${
            {
              light: 'text-black bg-gold bg-opacity-20 py-6 h-auto',
              dark: 'text-white bg-dark py-6 h-auto',
            }[theme]
          }`}
        >
          <div>
            <img
              src="/images/feature2.png"
              className="mx-auto mb-4 h-[520px]"
              alt="特徴2"
            />
            <div className="text-lg font-bold text-center">
              フーディーを探す
            </div>
            <p className="px-4 mt-4 text-sm">
              Foodies
              Primeに登録しているフーディーたちのプロフィールを検索することができます。
            </p>
            <p className="px-4 text-sm">
              気になる方がいたらメッセージを送り、食事会にお誘いすることもできます。
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide
          className={`inline-flex justify-center ${
            {
              light: 'text-black bg-gold bg-opacity-20 py-6 h-auto',
              dark: 'text-white bg-dark py-6 h-auto',
            }[theme]
          }`}
        >
          <div>
            <img
              src="/images/feature3.png"
              className="mx-auto mb-4 h-[520px]"
              alt="特徴3"
            />
            <div className="text-lg font-bold text-center">つぶやき</div>
            <p className="px-4 mt-4 text-sm">
              フーディーたちに質問をしたり、新店などの情報交換をしたりできます。
            </p>
            <p className="px-4 text-sm">
              有益な情報には、ギフトを贈り、感謝の気持ちを伝えることもできます。
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
      <ArrowSwiper id="button_prev" direction="left" isHidden={isBeginning} />
      <ArrowSwiper id="button_next" direction="right" isHidden={isEnd} />
    </div>
  )
}

const Features = () => {
  const theme = useTheme()
  return (
    <>
      <div
        className={`hidden md:block pb-20 ${
          {
            light: 'text-black bg-gold bg-opacity-20',
            dark: 'text-white bg-dark',
          }[theme]
        }`}
      >
        <div
          className={`flex items-start justify-around pt-10`}
          style={{
            background: 'linear-gradient(180deg, #B79650 50%, transparent 50%)',
          }}
        >
          <div className="w-80">
            <img
              src="/images/feature1.png"
              className="mx-auto mb-6"
              alt="特徴1"
            />
            <div className="text-lg font-bold text-center">食事会募集</div>
            <p className="mt-4 text-sm">
              さまざまな条件で食事会の参加者を募集することができます。また、募集中の食事会に応募することもできます。
            </p>
          </div>
          <div className="w-80">
            <img
              src="/images/feature2.png"
              className="mx-auto mb-6"
              alt="特徴2"
            />
            <div className="text-lg font-bold text-center">
              フーディーを探す
            </div>
            <p className="mt-4 text-sm">
              Foodies
              Primeに登録しているフーディーたちのプロフィールを検索することができます。
              気になる方がいたらメッセージを送り、食事会にお誘いすることもできます。
            </p>
          </div>
          <div className="w-80">
            <img
              src="/images/feature3.png"
              className="mx-auto mb-6"
              alt="特徴3"
            />
            <div className="text-lg font-bold text-center">つぶやき</div>
            <p className="mt-4 text-sm">
              フーディーたちに質問をしたり、新店などの情報交換をしたりできます。
            </p>
            <p className="text-sm">
              有益な情報には、ギフトを贈り、感謝の気持ちを伝えることもできます。
            </p>
          </div>
        </div>
      </div>

      <FeaturesMobile />
    </>
  )
}

const Experience = () => {
  const theme = useTheme()
  return (
    <div className="px-4 py-10 text-center text-white bg-secondary md:py-20">
      <img
        src="/images/experience_foodies_prime.svg"
        alt="experience"
        className="mx-auto"
      />
      <p className="mt-2 text-sm">Foodies Primeを体験する</p>

      <div className="mt-10 text-sm">
        <p className="my-2">本サービスは招待制となっておりますので、</p>
        <p className="my-2">会員の方にご招待いただくか、</p>
        <p className="my-2">下ボタンから登録申請をお願い致します。</p>
      </div>

      <div
        className={`mt-10 md:w-[577px] mx-auto ${
          { light: 'text-black bg-white', dark: 'text-white bg-dark' }[theme]
        }`}
      >
        <div className="gap-4 px-4 py-8 space-y-4 md:flex md:justify-center md:space-y-0">
          <div>
            <p className="text-sm font-bold">非会員の方はこちら</p>
            <a
              className="block py-2 mx-auto mt-1 text-base text-white bg-secondary w-60"
              href={config.applyUrl}
            >
              登録申請する
            </a>
          </div>

          <div>
            <p className="text-sm font-bold">会員の方はこちら</p>
            <Link
              href={config.loginUrl.line()}
              className="block py-2 mx-auto mt-1 text-base text-white bg-secondary w-60"
            >
              LINE でログイン
            </Link>
          </div>
        </div>
      </div>

      <div className="sm:flex sm:items-center justify-center gap-4 mt-6 text-white">
        <p className="text-sm">アプリ版ダウンロード</p>

        <div className="flex items-center justify-center gap-4 mt-4">
          <a href={config.appstoreUrl}>
            <img src="/images/appstore.svg" alt="appstore" />
          </a>
          <a href={config.googleplayUrl}>
            <img src="/images/googleplay.svg" alt="googleplay" />
          </a>
        </div>
      </div>
    </div>
  )
}

const Arrow = () => {
  const theme = useTheme()
  return (
    <div
      className={`border-t-2 border-r-2 w-[6px] h-[6px] rotate-45 ${
        { light: 'border-[#231815]', dark: 'border-white' }[theme]
      }`}
    />
  )
}

const Footer = () => {
  const theme = useTheme()
  const menues = [
    { label: '利用規約', href: '/static/tos' },
    { label: 'プライバシーポリシー', href: '/static/privacy' },
    { label: '特定商取引法に基づく表記', href: '/static/tokutei' },
  ]
  return (
    <footer
      className={`text-[#231815] ${
        { light: 'text-black bg-white', dark: 'text-white bg-dark' }[theme]
      }`}
    >
      <div className="px-6 py-12 mx-auto max-w-7xl md:flex md:items-center md:justify-between md:px-8">
        <div className="text-xs sm:space-x-6 sm:justify-center sm:flex">
          {menues.map((menu) => (
            <div className="flex items-center gap-2" key={menu.href}>
              <Arrow />
              <a href={menu.href}>{menu.label}</a>
            </div>
          ))}
        </div>
        <div className="mt-8 text-xs text-center md:order-1 md:mt-0">
          COPYRIGHT © Foodies Prime. ALL RIGHTS RESERVED.
        </div>
      </div>
    </footer>
  )
}

const FooterLink = () => {
  const theme = useTheme()

  return (
    <div
      className={`sticky bottom-0 z-10 px-4 py-4 ${
        { light: 'text-black bg-[#DDDDDD]', dark: 'text-white bg-black' }[theme]
      }`}
    >
      <div className="flex items-center justify-center gap-2 sm:gap-4 text-xs font-bold text-center">
        <a
          className="px-4 sm:px-8 py-2 rounded-sm bg-secondary text-white"
          href={config.applyUrl}
        >
          登録申請
        </a>

        <Link
          href={config.loginUrl.line()}
          className="px-4 sm:px-8 py-2 rounded-sm bg-secondary text-white"
        >
          LINE でログイン
        </Link>

        <div className="sm:flex sm:items-center sm:gap-2 hidden">
          <p className="hidden sm:block">会員専用ポイント購入</p>
          <Link
            href="/account/points/charge"
            className="px-4 sm:px-8 py-2 rounded-sm bg-primary text-white"
          >
            ポイント購入
          </Link>
        </div>
        <Link
          href="/account/points/charge"
          className="px-4 sm:px-8 py-2 rounded-sm bg-primary text-white sm:hidden"
        >
          ポイント購入
        </Link>

        <div
          className={`sm:flex sm:items-center justify-center gap-2 hidden ${
            { light: 'text-black', dark: 'text-white' }[theme]
          }`}
        >
          <p className="text-sm">アプリ版ダウンロード</p>

          <div className="flex items-center justify-center gap-4">
            <a href={config.appstoreUrl}>
              <img src="/images/appstore.svg" alt="appstore" />
            </a>
            <a href={config.googleplayUrl}>
              <img src="/images/googleplay.svg" alt="googleplay" />
            </a>
          </div>
        </div>
      </div>

      <div
        className={`sm:hidden flex gap-4 px-4 mt-4 ${
          { light: 'text-black', dark: 'text-white' }[theme]
        }`}
      >
        <a
          className="text-xs flex items-center gap-2"
          href={config.appstoreUrl}
        >
          <Arrow />
          iOS版ダウンロード
        </a>

        <a
          className="text-xs flex items-center gap-2"
          href={config.googleplayUrl}
        >
          <Arrow />
          Android版ダウンロード
        </a>
      </div>
    </div>
  )
}

const Page = () => {
  return (
    <>
      <Hero />
      <About />
      <Pictures />
      <Features />
      <Experience />
      <Footer />
      <FooterLink />
    </>
  )
}

export default Page
